import React from 'react'

function Presentation() {
  return (
    <div >
      <iframe src="https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/02/10090617/Utvecklare-hos-Webdura.pdf" style={{width: "100%",minHeight:"100vh",border: "none", overflow:'hidden', margin:"0px"}} type="application/pdf"></iframe>
    </div>
  )
}

export default Presentation